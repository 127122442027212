import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
    components: {
        Header,
        Footer,
    }, //添加注册组件事件
    data() {
        return {

        }
    },
    created() {},
    mounted() {},
    destroyed() {},
    methods: {},
};